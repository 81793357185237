var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("Users")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { span: _vm.layoutSpan } },
            [
              _c(
                "el-button",
                {
                  staticClass: "add-button",
                  on: {
                    click: function ($event) {
                      return _vm.AddUser()
                    },
                  },
                },
                [_vm._v("Add A User")]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { "show-header": false, data: _vm.users },
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-s-custom info-icon",
                                        }),
                                      ]),
                                      _c("el-col", { attrs: { span: 22 } }, [
                                        _c(
                                          "h3",
                                          { staticClass: "info-header" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(prop.row.firstname) +
                                                " " +
                                                _vm._s(prop.row.lastname) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "Email: " + _vm._s(prop.row.email)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            "Phone: " +
                                              _vm._s(prop.row.mobilephone)
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "el-button--mini",
                                      attrs: {
                                        to: {
                                          name: "UserDetails",
                                          params: {
                                            username: prop.row.username,
                                          },
                                        },
                                        tag: "el-button",
                                      },
                                    },
                                    [_vm._v("Details")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteUser(
                                            prop.row.username
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }