var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "navWrapper" }, [
        _c(
          "div",
          {
            staticClass: "navbar",
            class: { active: _vm.isActive },
            attrs: { id: "menu" },
          },
          [
            _c(
              "el-menu",
              { attrs: { mode: "horizontal", router: true } },
              [
                _c(
                  "el-menu-item",
                  {
                    attrs: { id: "logo", index: "/", route: { name: "Jobs" } },
                  },
                  [
                    _c("div", { staticClass: "main-logo" }, [
                      _c("i", {
                        staticClass: "el-icon-d-arrow-right logo-icon",
                      }),
                      _c("label", { staticClass: "logo-title" }, [
                        _vm._v("Pay It Forward"),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "el-menu-item",
                  { attrs: { index: "/jobs", route: { name: "Jobs" } } },
                  [_vm._v(" Jobs ")]
                ),
                _c(
                  "el-menu-item",
                  {
                    attrs: {
                      index: "/candidate",
                      route: { name: "Candidates" },
                    },
                  },
                  [_vm._v(" Candidates ")]
                ),
                _c(
                  "el-menu-item",
                  {
                    attrs: { index: "/company", route: { name: "Companies" } },
                  },
                  [_vm._v(" Company ")]
                ),
                _c(
                  "el-menu-item",
                  { attrs: { index: "/users", route: { name: "Users" } } },
                  [_vm._v(" Users ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            attrs: { id: "toggle" },
            on: {
              click: function ($event) {
                return _vm.select()
              },
            },
          },
          [
            _c("div", {
              staticClass: "span",
              class: { active: _vm.isActive },
              attrs: { id: "top" },
            }),
            _c("div", {
              staticClass: "span",
              class: { active: _vm.isActive },
              attrs: { id: "middle" },
            }),
            _c("div", {
              staticClass: "span",
              class: { active: _vm.isActive },
              attrs: { id: "bottom" },
            }),
          ]
        ),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _c(
              "el-dropdown",
              { staticClass: "avatar-container", attrs: { trigger: "click" } },
              [
                _c(
                  "div",
                  { staticClass: "avatar-wrapper" },
                  [
                    _c("el-avatar", {
                      attrs: { shape: "square", size: 40, src: _vm.squareUrl },
                    }),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [_c("el-dropdown-item", [_vm._v(" Home ")])],
                      1
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/profile" } },
                      [_c("el-dropdown-item", [_vm._v(" My Profile ")])],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.LogOut.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("Log Out"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("Breadcrumbs", { staticClass: "breadcrumb-container" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }