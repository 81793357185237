var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12, offset: 6 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("Candidate")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { gutter: 24 },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 6 } },
            [
              _c(
                "el-form",
                {
                  ref: "CandidateForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.CandidateForm,
                    rules: _vm.rules,
                    "label-position": "top",
                    "label-width": "240px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Candidate First Name",
                        prop: "firstname",
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.CandidateForm.firstname,
                          callback: function ($$v) {
                            _vm.$set(_vm.CandidateForm, "firstname", $$v)
                          },
                          expression: "CandidateForm.firstname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Candidate Last Name", prop: "lastname" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.CandidateForm.lastname,
                          callback: function ($$v) {
                            _vm.$set(_vm.CandidateForm, "lastname", $$v)
                          },
                          expression: "CandidateForm.lastname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Candidate's Field", prop: "fieldid" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.CandidateForm.fieldid,
                            callback: function ($$v) {
                              _vm.$set(_vm.CandidateForm, "fieldid", $$v)
                            },
                            expression: "CandidateForm.fieldid",
                          },
                        },
                        _vm._l(_vm.fields, function (field) {
                          return _c("el-option", {
                            key: field.fieldid,
                            attrs: {
                              label: field.field_name,
                              value: field.fieldid,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Candidate Search Type",
                        prop: "categoryid",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.CandidateForm.categoryid,
                            callback: function ($$v) {
                              _vm.$set(_vm.CandidateForm, "categoryid", $$v)
                            },
                            expression: "CandidateForm.categoryid",
                          },
                        },
                        _vm._l(_vm.categories, function (category) {
                          return _c("el-option", {
                            key: category.categories,
                            attrs: {
                              label: category.category_name,
                              value: category.categoryid,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Sponsor",
                        prop: "pifsponsorid",
                        placeholder: "Select",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.CandidateForm.pifsponsorid,
                            callback: function ($$v) {
                              _vm.$set(_vm.CandidateForm, "pifsponsorid", $$v)
                            },
                            expression: "CandidateForm.pifsponsorid",
                          },
                        },
                        _vm._l(_vm.pifsponsors, function (sponsor) {
                          return _c("el-option", {
                            key: sponsor.rosterid,
                            attrs: {
                              label: sponsor.lastname,
                              value: String(sponsor.rosterid),
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-checkbox", {
                    staticClass: "checks",
                    attrs: { label: "Upload a resume" },
                    model: {
                      value: _vm.resumeChecked,
                      callback: function ($$v) {
                        _vm.resumeChecked = $$v
                      },
                      expression: "resumeChecked",
                    },
                  }),
                  _c("el-checkbox", {
                    staticClass: "checks",
                    attrs: { label: "Include a Cover Letter" },
                    model: {
                      value: _vm.coverletterChecked,
                      callback: function ($$v) {
                        _vm.coverletterChecked = $$v
                      },
                      expression: "coverletterChecked",
                    },
                  }),
                  _c("el-checkbox", {
                    staticClass: "checks",
                    attrs: { label: "Include supporting documents" },
                    model: {
                      value: _vm.supportingdocumentsChecked,
                      callback: function ($$v) {
                        _vm.supportingdocumentsChecked = $$v
                      },
                      expression: "supportingdocumentsChecked",
                    },
                  }),
                  _c("br"),
                  _vm.resumeChecked
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { attrs: { id: "resume-bay" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Upload a resume" } },
                                [
                                  !_vm.showMobile
                                    ? _c(
                                        "el-upload",
                                        {
                                          ref: "uploadresume",
                                          attrs: {
                                            multiple: false,
                                            drag: "",
                                            action: "#",
                                            "auto-upload": false,
                                            limit: 1,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-upload",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "el-upload__text" },
                                            [
                                              _vm._v(" Drop file here or "),
                                              _c("em", [
                                                _vm._v("click to upload"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-upload",
                                        {
                                          ref: "uploadresume",
                                          attrs: {
                                            multiple: false,
                                            action: "#",
                                            "auto-upload": false,
                                            limit: 1,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "tiny",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("Click to browse")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.CandidateForm.id !== 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.resumeUploading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.handleUpload(
                                        "resume",
                                        _vm.getDocumentsToUpload("resume").raw
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Upload")]
                              )
                            : _vm._e(),
                          "resume" in this.CandidateForm.documentsList
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        title: "Resume already on file",
                                        visible: _vm.resumeReplacer,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.resumeReplacer = $event
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "You already have a resume document on file: '" +
                                            _vm._s(
                                              _vm.getSavedDocuments("resume")
                                                .filename
                                            ) +
                                            "'"
                                        ),
                                      ]),
                                      _c("br"),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "You may opt to replace the currently saved document with the new file, or cancel this upload attempt."
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer",
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.resumeReplacer = false
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.handleUpload(
                                                    "resume",
                                                    _vm.getDocumentsToUpload(
                                                      "resume"
                                                    ).raw
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Replace " +
                                                  _vm._s(
                                                    _vm.getSavedDocuments(
                                                      "resume"
                                                    ).filename
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm.coverletterChecked
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { attrs: { id: "coverletter-bay" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    id: "cover-letter-upload",
                                    label: "Upload a cover letter",
                                  },
                                },
                                [
                                  !_vm.showMobile
                                    ? _c(
                                        "el-upload",
                                        {
                                          ref: "uploadcoverletter",
                                          attrs: {
                                            drag: "",
                                            multiple: false,
                                            action: "#",
                                            "auto-upload": false,
                                            limit: 1,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-upload",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "el-upload__text" },
                                            [
                                              _vm._v(" Drop file here or "),
                                              _c("em", [
                                                _vm._v("click to upload"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-upload",
                                        {
                                          ref: "uploadcoverletter",
                                          attrs: {
                                            multiple: false,
                                            action: "#",
                                            "auto-upload": false,
                                            limit: 1,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "tiny",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("Click to browse")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.CandidateForm.id !== 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.coverletterUploading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.handleUpload(
                                        "coverletter",
                                        _vm.getDocumentsToUpload("coverletter")
                                          .raw
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Upload")]
                              )
                            : _vm._e(),
                          "coverletter" in this.CandidateForm.documentsList
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        title: "Coverletter already on file",
                                        visible: _vm.coverletterReplacer,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.coverletterReplacer = $event
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "You already have a coverletter document on file: '" +
                                            _vm._s(
                                              _vm.getSavedDocuments(
                                                "coverletter"
                                              ).filename
                                            ) +
                                            "'"
                                        ),
                                      ]),
                                      _c("br"),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "You may opt to replace the currently saved document with the new file, or cancel this upload attempt."
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer",
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.coverletterReplacer = false
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.handleUpload(
                                                    "coverletter",
                                                    _vm.getDocumentsToUpload(
                                                      "coverletter"
                                                    ).raw
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Replace " +
                                                  _vm._s(
                                                    _vm.getSavedDocuments(
                                                      "coverletter"
                                                    ).filename
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.supportingdocumentsChecked
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { attrs: { id: "supportingdocuments-bay" } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    id: "additional-docs-upload",
                                    label: "Additional/supporting documents",
                                  },
                                },
                                [
                                  !_vm.showMobile
                                    ? _c(
                                        "el-upload",
                                        {
                                          ref: "uploadsupportingdocuments",
                                          attrs: {
                                            drag: "",
                                            multiple: true,
                                            action: "#",
                                            "auto-upload": false,
                                            limit: 2,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-upload",
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "el-upload__text" },
                                            [
                                              _vm._v(" Drop file here or "),
                                              _c("em", [
                                                _vm._v("click to upload"),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "el-upload",
                                        {
                                          ref: "uploadsupportingdocuments",
                                          attrs: {
                                            multiple: true,
                                            action: "#",
                                            "auto-upload": false,
                                            limit: 2,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "tiny",
                                                type: "primary",
                                              },
                                            },
                                            [_vm._v("Click to browse")]
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.CandidateForm.id !== 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.supportingdocumentsUploading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.handleUpload(
                                        "supportingdocuments",
                                        _vm.getDocumentsToUpload(
                                          "supportingdocuments"
                                        )
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Upload")]
                              )
                            : _vm._e(),
                          "supportingdocuments" in
                          this.CandidateForm.documentsList
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: {
                                        title:
                                          "supporting document(s) already on file",
                                        visible:
                                          _vm.supportingdocumentsReplacer,
                                      },
                                      on: {
                                        "update:visible": function ($event) {
                                          _vm.supportingdocumentsReplacer =
                                            $event
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "You already have one or more supporting documents on file: "
                                        ),
                                      ]),
                                      _vm._l(
                                        _vm.CandidateForm.documentsList[
                                          "supportingdocuments"
                                        ],
                                        function (doc) {
                                          return _c(
                                            "span",
                                            { key: doc.filename },
                                            [
                                              _vm._v(
                                                " '" +
                                                  _vm._s(doc.filename) +
                                                  "' "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("br"),
                                      _c("br"),
                                      _vm.CandidateForm.documentsList[
                                        "supportingdocuments"
                                      ].length == 1 &&
                                      _vm.getDocumentsToUpload(
                                        "supportingdocuments"
                                      ).length == 2
                                        ? _c("span", [
                                            _vm._v(
                                              " By uploading the desired documents, the existing document on file will be replaced. If you would like to keep the current saved document, click 'cancel' below to cancel this attempt, and remove one document from the upload queue in order to upload one document and keep the currently saved document. "
                                            ),
                                          ])
                                        : _vm.CandidateForm.documentsList[
                                            "supportingdocuments"
                                          ].length == 2 &&
                                          _vm.getDocumentsToUpload(
                                            "supportingdocuments"
                                          ).length == 1
                                        ? _c("span", [
                                            _vm._v(
                                              " In order to upload the desired document, an existing document must be replaced. Please select a document to replace below, or press 'cancel' to cancel this attempt. "
                                            ),
                                          ])
                                        : _vm.CandidateForm.documentsList[
                                            "supportingdocuments"
                                          ].length == 2 &&
                                          _vm.getDocumentsToUpload(
                                            "supportingdocuments"
                                          ).length == 2
                                        ? _c("span", [
                                            _vm._v(
                                              " In order to upload the desired documents, both currently saved documents must be replaced. If you would like to replace both documents, you may do so. Otherwise, press 'cancel' to cancel this attempt. "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "dialog-footer",
                                          attrs: { slot: "footer" },
                                          slot: "footer",
                                        },
                                        [
                                          _vm.CandidateForm.documentsList[
                                            "supportingdocuments"
                                          ].length == 2 &&
                                          _vm.getDocumentsToUpload(
                                            "supportingdocuments"
                                          ).length == 2
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.replaceFile(
                                                            "supportingdocuments",
                                                            _vm.getDocumentsToUpload(
                                                              "supportingdocuments"
                                                            ),
                                                            "all"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Replace current documents "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm.CandidateForm.documentsList[
                                                "supportingdocuments"
                                              ].length == 2 &&
                                              _vm.getDocumentsToUpload(
                                                "supportingdocuments"
                                              ).length == 1
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.CandidateForm
                                                    .documentsList[
                                                    "supportingdocuments"
                                                  ],
                                                  function (doc, index) {
                                                    return _c(
                                                      "el-button",
                                                      {
                                                        key: doc.filename,
                                                        attrs: {
                                                          type: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.replaceFile(
                                                              "supportingdocuments",
                                                              _vm.getDocumentsToUpload(
                                                                "supportingdocuments"
                                                              )[0],
                                                              _vm.CandidateForm
                                                                .documentsList[
                                                                "supportingdocuments"
                                                              ][index]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Replace " +
                                                            _vm._s(
                                                              doc.filename
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm.CandidateForm.documentsList[
                                                "supportingdocuments"
                                              ].length == 1 &&
                                              _vm.getDocumentsToUpload(
                                                "supportingdocuments"
                                              ).length == 2
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.replaceFile(
                                                            "supportingdocuments",
                                                            _vm.getDocumentsToUpload(
                                                              "supportingdocuments"
                                                            ),
                                                            "all"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Replace " +
                                                          _vm._s(
                                                            _vm.CandidateForm
                                                              .documentsList[
                                                              "supportingdocuments"
                                                            ][0].filename
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.supportingdocumentsReplacer = false
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("i", { staticClass: "fileupload-info" }, [
                    _vm._v(
                      " Acceptable file types: .pdf, .doc, .docx, .odt, .txt, .odt "
                    ),
                  ]),
                  _c("br"),
                  _c("i", { staticClass: "fileupload-info" }, [
                    _vm._v("Maximum file size: 100MB"),
                  ]),
                  _c("br"),
                  _c("i", { staticClass: "fileupload-info" }, [
                    _vm._v("Maximum two supporting documents"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Notes", prop: "notes" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", row: 3 },
                        model: {
                          value: _vm.CandidateForm.notes,
                          callback: function ($$v) {
                            _vm.$set(_vm.CandidateForm, "notes", $$v)
                          },
                          expression: "CandidateForm.notes",
                        },
                      }),
                    ],
                    1
                  ),
                  Object.keys(_vm.CandidateForm.documentsList).length > 0
                    ? _c("Viewer", {
                        key: _vm.CandidateForm.documentsList.flip,
                        attrs: {
                          documentsList: _vm.CandidateForm.documentsList,
                          candidateId: _vm.CandidateForm.id,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("CandidateForm")
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }