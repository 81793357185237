<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="">
          <h1 class="page-header">Jobs</h1>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col v-show="!showPrint" :span="searchSpan">
        <el-form
          ref="form"
          label-position="top"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="Job Title">
            <el-input v-model="form.jobtitle"></el-input>
          </el-form-item>
          <el-form-item label="Job Field">
            <el-select v-model="form.fieldid" placeholder="Candidate's Field">
              <el-option
                v-for="field in fields"
                :key="field.fieldid"
                :label="field.field_name"
                :value="field.fieldid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Sponsor">
            <el-select v-model="form.pifsponsorid" placeholder="Sponsor">
              <el-option
                v-for="sponsor in pifsponsors"
                :key="sponsor.rosterid"
                :label="sponsor.lastname"
                :value="sponsor.rosterid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Company">
            <el-select v-model="form.companyid" placeholder="Company">
              <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.companyname"
                :value="company.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Date listed between...">
            <el-date-picker
              v-model="form.datelistedafter"
              type="date"
              placeholder="All listings after..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-date-picker
              v-model="form.datelistedbefore"
              type="date"
              placeholder="All listings before..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Date last updated between...">
            <el-date-picker
              v-model="form.dateupdatedafter"
              type="date"
              placeholder="All listings after..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-date-picker
              v-model="form.dateupdatedbefore"
              type="date"
              placeholder="All listings before..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button v-loading="loading" type="primary" @click="submitForm"
              >Search</el-button
            >
            <el-button>Clear</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="layoutSpan">
        <el-button class="add-button hide-for-print" @click="AddJob()"
          >Add A Job</el-button
        >
        <el-button
          class="add-button hide-for-print"
          type="primary"
          @click="TogglePrintView()"
        >
          <i class="el-icon-printer" /> Toggle Print View
        </el-button>
        <el-table
          v-show="showPrint"
          v-loading="loading"
          :show-header="false"
          :data="jobs"
          style="width: 100%"
          id="data-table"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <h3 class="info-header">{{ prop.row.jobtitle }}</h3>
                  <el-row class="printer-friendly-row">
                    <div class="printer-friendly-col">
                      <span class="printer-friendly-col--label">Company:</span>
                      <span>{{ prop.row.companyname }}</span
                      ><br />
                      <span class="printer-friendly-col--label">Sponsor:</span>
                      <span>{{ prop.row.pifsponsor }}</span
                      ><br />
                      <span class="printer-friendly-col--label"
                        >Date Listed:</span
                      >
                      <span>{{ prop.row.datelisted }}</span
                      ><br />
                    </div>
                    <div class="printer-friendly-col">
                      <span class="printer-friendly-col--label"
                        ># Openings:</span
                      >
                      <span>{{ prop.row.jobopenings }}</span
                      ><br />
                      <span class="printer-friendly-col--label">Field:</span>
                      <span>{{ prop.row.field_name }}</span
                      ><br />
                      <span class="printer-friendly-col--label"
                        >Last Updated:</span
                      >
                      <span>{{ prop.row.lastupdated }}</span
                      ><br />
                    </div>
                  </el-row>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-show="showMobile && !showPrint"
          v-loading="loading"
          :show-header="false"
          :data="jobs"
          style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <el-row>
                    <el-col :span="22">
                      <h3 class="info-header">{{ prop.row.jobtitle }}</h3>
                      <span>Company: {{ prop.row.companyname }}</span
                      ><br />
                      <span>Number of Openings: {{ prop.row.jobopenings }}</span
                      ><br />
                      <span>Sponsor: {{ prop.row.pifsponsor }}</span
                      ><br />
                      <span>Field: {{ prop.row.field_name }}</span
                      ><br />
                      <span>Date Listed: {{ prop.row.datelisted }}</span>
                      <br />
                      <span>Last Updated: {{ prop.row.lastupdated }}</span>
                      <br />
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'JobDetails', params: { id: prop.row.id } }"
                    tag="el-button"
                    class="el-button--mini"
                    >Details</router-link
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="deleteJob(prop.row.id)"
                    >Delete</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          id="jobs-table"
          v-show="!showMobile && !showPrint"
          v-loading="loading"
          :show-header="true"
          :data="jobs"
          default-expand-all
          style="width: 100%"
        >
          <el-table-column type="expand">
            <template slot-scope="prop" v-if="prop.row.notes.length > 0">
              <p id="notes" style="margin-left: 10px">{{ prop.row.notes }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="jobtitle"
            label="Job Title"
            sortable
          ></el-table-column>
          <el-table-column
            prop="field_name"
            label="Job Field"
            sortable
          ></el-table-column>
          <el-table-column
            prop="companyname"
            label="Company Name"
            sortable
          ></el-table-column>
          <el-table-column
            prop="jobopenings"
            label="Openings"
            sortable
          ></el-table-column>
          <el-table-column
            prop="datelisted"
            label="Date Listed"
            sortable
          ></el-table-column>
          <el-table-column
            prop="lastupdated"
            label="Last Updated"
            sortable
          ></el-table-column>
          <el-table-column
            prop="pifsponsor"
            label="Sponsor"
            sortable
          ></el-table-column>
          <el-table-column label="Controls">
            <template slot-scope="prop">
              <div>
                <router-link
                  :to="{ name: 'JobDetails', params: { id: prop.row.id } }"
                  tag="el-button"
                  class="el-button--mini"
                  >Details</router-link
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="deleteJob(prop.row.id)"
                  >Delete</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="hide-for-print"
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from "../../components/base/Pagination.vue";
import { fetchList } from "../../utils/filter.js";
const api = require("../../api/api.js");

export default {
  name: "jobs",
  components: {
    Pagination,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      message: [],
      errors: [],
      jobs: null,
      list: null,
      total: 0,
      loading: true,
      listQuery: {
        page: 1,
        limit: 50,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      form: {
        jobtitle: "",
        companyid: null,
        pifsponsorid: null,
        fieldid: null,
      },
      screenWidth: window.innerWidth,
      searchSpan: 6,
      layoutSpan: 18,
      fields: {},
      pifsponsors: {},
      companies: {},
      showMobile: false,
      showPrint: false,
    };
  },

  async mounted() {
    this.loading = true;
    const that = this;

    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    await this.fetchData();

    // Hide blank notes
    this.$nextTick(() => {
      const trs = [].slice.call(
        document.querySelectorAll(
          "#jobs-table > div.el-table__body-wrapper.is-scrolling-none > table > tbody > tr"
        )
      );
      this.jobs.forEach((row, index) => {
        let tds = trs[index + 2].querySelector("td");
        if (tds) {
          let divs = tds.querySelector("div");
          let ps = tds.querySelector("#notes");
          if (!divs && !ps) {
            tds.remove();
          }
        }
      });
    });

    this.loading = false;
  },
  watch: {
    $route: "fetchData",
    screenWidth(val) {
      this.screenWidth = val;
      this.setLayout();
    },
  },
  methods: {
    TogglePrintView() {
      this.showPrint = !this.showPrint;
    },
    setLayout() {
      if (this.screenWidth < 900) {
        this.layoutSpan = 24;
        this.searchSpan = 24;
        this.showMobile = true;
      } else {
        this.layoutSpan = 18;
        this.searchSpan = 6;
        this.showMobile = false;
      }
    },
    async fetchData() {
      this.setLayout();

      try {
        await Promise.all([
          api.get("admin/field").then((fields) => (this.fields = fields)),
          api
            .get("users?request=ids")
            .then((sponsors) => (this.pifsponsors = sponsors)),
          api.get("company").then((companies) => (this.companies = companies)),
          api.get("jobs").then((jobs) => (this.list = jobs)),
        ]);
      } catch (e) {
        console.error(e);
        this.$notify.error("Failed to retrieve listing information.");
      }

      this.jobs = fetchList(this.listQuery, this.list);
      this.total = this.list.length;
    },
    async deleteJob(id) {
      try {
        this.loading = true;
        await api.remove("jobs", id);
      } catch (e) {
        this.$notify.error("Error deleting job.");
      }

      await this.fetchData();
      this.loading = false;
    },
    AddJob() {
      this.$router.push({
        name: "JobsNew",
      });
    },

    setDateSearchParamsByType(searchType) {
      const form = this.form;

      if (form[`date${searchType}before`]) {
        form[`date${searchType}before`] = form[`date${searchType}before`]
          .toISOString()
          .split("T")[0];
      } else {
        form[`date${searchType}before`] = new Date()
          .toISOString()
          .split("T")[0];
      }

      if (form[`date${searchType}after`]) {
        form[`date${searchType}after`] = form[`date${searchType}after`]
          .toISOString()
          .split("T")[0];
      } else {
        form[`date${searchType}after`] = "1970-01-01"; // first day of Unix should be far enough back
      }
    },

    resetSearch() {
      const form = this.form;

      (form.jobtitle = ""),
        (form.companyid = null),
        (form.pifsponsorid = null),
        (form.fieldid = null),
        (form.datelistedafter = undefined),
        (form.datelistedbefore = undefined),
        (form.dateupdatedafter = undefined),
        (form.dateupdatedbefore = undefined);
    },

    async submitForm() {
      const form = this.form;
      if (form.datelistedafter || form.datelistedbefore) {
        this.setDateSearchParamsByType("listed");
      }

      if (form.dateupdatedafter || form.dateupdatedbefore) {
        this.setDateSearchParamsByType("updated");
      }

      this.loading = true;
      try {
        const postTask = api.post("jobs/search", form);
        this.resetSearch();
        const data = await postTask;
        if (data === "") {
          // prevent the Vue Warn for invalid prop (expected array ... got string)
          this.list = [];
        } else {
          this.list = data;
        }
      } catch (e) {
        this.$notify.error("Error performing job search.");
      }

      this.jobs = fetchList(this.listQuery, this.list);
      this.loading = false;
    },

    getList() {
      this.loading = true;
      this.jobs = fetchList(this.listQuery, this.list);
      this.total = this.list.length;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/print-view.scss";

.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
</style>
