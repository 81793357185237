var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.confirmMFASetup
      ? _c(
          "div",
          { staticClass: "login-container" },
          [
            _c(
              "el-form",
              {
                ref: "registrationForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.registrationForm,
                  rules: _vm.loginRules,
                  "auto-complete": "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Pay It Forward."),
                  ]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "legalName" } },
                  [
                    _c("span", { staticClass: "svg-container" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                    ]),
                    _c("el-input", {
                      ref: "legalName",
                      attrs: {
                        placeholder: "Name",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.registrationForm.legalName,
                        callback: function ($$v) {
                          _vm.$set(_vm.registrationForm, "legalName", $$v)
                        },
                        expression: "registrationForm.legalName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "username" } },
                  [
                    _c("span", { staticClass: "svg-container" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                    ]),
                    _c("el-input", {
                      ref: "username",
                      attrs: {
                        placeholder: "Username",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.registrationForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.registrationForm, "username", $$v)
                        },
                        expression: "registrationForm.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "oldPassword" } },
                  [
                    _c("span", { staticClass: "svg-container" }, [
                      _c("i", { staticClass: "el-icon-unlock" }),
                    ]),
                    _c("el-input", {
                      key: _vm.passwordType,
                      ref: "password",
                      attrs: {
                        type: _vm.passwordType,
                        placeholder: "Provided System Password",
                        name: "oldPassword",
                        tabindex: "2",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.registrationForm.oldPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.registrationForm, "oldPassword", $$v)
                        },
                        expression: "registrationForm.oldPassword",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "show-password",
                        on: { click: _vm.showPassword },
                      },
                      [
                        _c("i", {
                          class:
                            _vm.passwordType === "password"
                              ? "el-icon-view"
                              : "el-icon-lock",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "newPassword" } },
                  [
                    _c("span", { staticClass: "svg-container" }, [
                      _c("i", { staticClass: "el-icon-unlock" }),
                    ]),
                    _c("el-input", {
                      key: _vm.passwordType,
                      ref: "password",
                      attrs: {
                        type: _vm.passwordType,
                        placeholder: "Your Password",
                        name: "newPassword",
                        tabindex: "2",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.registrationForm.newPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.registrationForm, "newPassword", $$v)
                        },
                        expression: "registrationForm.newPassword",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "show-password",
                        on: { click: _vm.showPassword },
                      },
                      [
                        _c("i", {
                          class:
                            _vm.passwordType === "password"
                              ? "el-icon-view"
                              : "el-icon-lock",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "password-requirements" }, [
                  _vm._v(
                    " passwords must meet the following requirements: Minimum of 8 characters in length, include one lowercase letter, one uppercase letter, and one special character at minimum. Special characters may be selected from the following list: " +
                      _vm._s(_vm.specialCharacterList) +
                      " "
                  ),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "confirmPassword" } },
                  [
                    _c("span", { staticClass: "svg-container" }, [
                      _c("i", { staticClass: "el-icon-unlock" }),
                    ]),
                    _c("el-input", {
                      key: _vm.passwordType,
                      ref: "password",
                      attrs: {
                        type: _vm.passwordType,
                        placeholder: "Confirm Your Password",
                        name: "confirmPassword",
                        tabindex: "2",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.registrationForm.confirmPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.registrationForm, "confirmPassword", $$v)
                        },
                        expression: "registrationForm.confirmPassword",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "show-password",
                        on: { click: _vm.showPassword },
                      },
                      [
                        _c("i", {
                          class:
                            _vm.passwordType === "password"
                              ? "el-icon-view"
                              : "el-icon-lock",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "margin-bottom": "30px",
                                  width: "100%",
                                },
                                attrs: {
                                  loading: _vm.loading,
                                  type: "primary",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.submitForm("registrationForm")
                                  },
                                },
                              },
                              [_vm._v("Complete Registration")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.confirmMFASetup
      ? _c(
          "div",
          { staticClass: "login-container" },
          [
            _c(
              "el-form",
              {
                ref: "mfaForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.mfaForm,
                  "auto-complete": "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", { staticClass: "title-container" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Pay It Forward."),
                  ]),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { prop: "legalName" } },
                  [
                    _c("span", { staticClass: "svg-container" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                    ]),
                    _c("el-input", {
                      ref: "legalName",
                      attrs: {
                        placeholder: "Confirm MFA Code",
                        name: "username",
                        type: "text",
                        tabindex: "1",
                        "auto-complete": "on",
                      },
                      model: {
                        value: _vm.mfaForm.mfaCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.mfaForm, "mfaCode", $$v)
                        },
                        expression: "mfaForm.mfaCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "margin-bottom": "30px",
                                  width: "100%",
                                },
                                attrs: {
                                  loading: _vm.loading,
                                  type: "primary",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.confirmMFACode.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("Confirm MFA Code")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }