<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="6"
        ><div class=""><h1 class="page-header">Candidates</h1></div></el-col
      >
    </el-row>
    <el-row :gutter="40">
      <el-button class="add-button hide-for-print" @click="AddCandidate()"
        >Add A Candidate</el-button
      >
      <el-button
        class="add-button hide-for-print"
        type="primary"
        @click="TogglePrintView()"
      >
        <i class="el-icon-printer" /> Toggle Print View
      </el-button>
      <el-table
        v-show="showPrint"
        v-loading="loading"
        :show-header="false"
        :data="candidates"
        style="width: 100%"
        id="data-table"
      >
        <el-table-column>
          <template slot-scope="prop">
            <div class="data-container">
              <div class="info">
                <h3 class="info-header">
                  {{ prop.row.firstname }} {{ prop.row.lastname }}
                </h3>
                <el-row class="printer-friendly-row">
                  <div class="printer-friendly-col">
                    <span class="printer-friendly-col--label">Field:</span>
                    <span>{{ prop.row.fieldname }}</span
                    ><br />
                    <span class="printer-friendly-col--label"
                      >Sponsor: {{ prop.row.sponsor }}</span
                    ><br />
                    <span class="printer-friendly-col--label"
                      >Date Listed: {{ prop.row.datelisted }}</span
                    ><br />
                  </div>
                  <div class="printer-friendly-col">
                    <span class="printer-friendly-col--label"
                      >Notes: {{ prop.row.notes }}</span
                    ><br />
                    <span class="printer-friendly-col--label"
                      >Last Updated: {{ prop.row.lastupdated }}</span
                    ><br />
                  </div>
                </el-row>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-col v-show="!showPrint" :span="searchSpan">
        <el-form
          ref="form"
          label-position="top"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="Candidate's First Name">
            <el-input v-model="form.firstname"></el-input>
          </el-form-item>
          <el-form-item label="Candidate's Last Name">
            <el-input v-model="form.lastname"></el-input>
          </el-form-item>
          <el-form-item label="Is Confidential">
            <el-select
              v-model="form.isconfidential"
              placeholder="Is Confidential"
            >
              <el-option label="true" value="true" />
              <el-option label="false" value="false" />
            </el-select>
          </el-form-item>
          <el-form-item label="Candidate's Field">
            <el-select v-model="form.fieldid" placeholder="Candidate's Field">
              <el-option
                v-for="field in fields"
                :key="field.fieldid"
                :label="field.field_name"
                :value="field.fieldid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Date listed between...">
            <el-date-picker
              v-model="form.datelistedafter"
              type="date"
              placeholder="All listings after..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-date-picker
              v-model="form.datelistedbefore"
              type="date"
              placeholder="All listings before..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Date last updated between...">
            <el-date-picker
              v-model="form.dateupdatedafter"
              type="date"
              placeholder="All listings after..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <el-date-picker
              v-model="form.dateupdatedbefore"
              type="date"
              placeholder="All listings before..."
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button v-loading="loading" type="primary" @click="submitForm"
              >Search</el-button
            >
            <el-button @click="resetSearch">Clear</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="layoutSpan">
        <el-table
          v-show="showMobile && !showPrint"
          v-loading="loading"
          height="650"
          :data="candidates"
          style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <el-row>
                    <el-col :span="2">
                      <i class="el-icon-s-custom info-icon" />
                    </el-col>
                    <el-col :span="22">
                      <h3 class="info-header">
                        {{ prop.row.firstname }} {{ prop.row.lastname }}
                      </h3>
                      <label style="font-size: 14px">
                        <span style="font-weight: bold"
                          >Candidate's Field:</span
                        >
                        {{ prop.row.fieldname }}
                      </label>
                      <br />
                      <span>Sponsor: {{ prop.row.sponsor }}</span>
                      <br />
                      <span>Notes: {{ prop.row.notes }}</span>
                      <br />
                      <span>Date Listed: {{ prop.row.datelisted }}</span>
                      <br />
                      <span>Last Updated: {{ prop.row.lastupdated }}</span>
                      <br />
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'CandidateDetails',
                      params: { id: prop.row.id },
                    }"
                    tag="el-button"
                    class="el-button--mini"
                    >Details</router-link
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="deleteCandidate(prop.row.id)"
                    >Delete</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-show="!showMobile && !showPrint"
          v-loading="loading"
          height="650"
          :data="candidates"
          style="width: 100%"
          border
          stripe
        >
          <el-table-column prop="firstname" label="First Name" sortable />
          <el-table-column prop="lastname" label="Last Name" sortable />
          <el-table-column prop="fieldname" label="Field" sortable />
          <el-table-column prop="sponsor" label="Sponsor" sortable />
          <el-table-column prop="categoryname" label="Status" sortable />
          <el-table-column
            prop="isconfidential"
            label="Confidential"
            sortable
          />
          <el-table-column prop="datelisted" label="Date Listed" sortable />
          <el-table-column prop="lastupdated" label="Last Updated" sortable />

          <el-table-column label="Controls">
            <template slot-scope="prop">
              <div style="content-align: center">
                <router-link
                  style="margin: auto"
                  :to="{
                    name: 'CandidateDetails',
                    params: { id: prop.row.id },
                  }"
                  tag="el-button"
                  class="el-button--mini"
                  >Detail</router-link
                >
                <el-button
                  style="margin: auto"
                  size="mini"
                  type="danger"
                  @click="deleteCandidate(prop.row.id)"
                  >Delete</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="hide-for-print"
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from "../../components/base/Pagination.vue";
import { fetchList } from "../../utils/filter.js";
//import store from "@/store/index.js";
const api = require("../../api/api.js");

export default {
  name: "candidates",
  components: {
    Pagination,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      message: [],
      errors: [],
      candidates: null,
      list: null,
      total: 0,
      loading: false,
      showMobile: false,
      listQuery: {
        page: 1,
        limit: 50,
        importance: undefined,
        title: undefined,
        fieldid: undefined,
        sort: "+id",
      },
      form: {
        firstname: "",
        lastname: "",
        fieldid: null,
      },
      fields: {},
      screenWidth: window.innerWidth,
      searchSpan: 6,
      layoutSpan: 18,
      showPrint: false,
    };
  },

  // Fetches posts when the component is created.
  async mounted() {
    const that = this;

    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
    screenWidth(val) {
      this.screenWidth = val;
      this.setLayout();
    },
  },
  methods: {
    setLayout() {
      if (this.screenWidth < 900) {
        this.layoutSpan = 24;
        this.searchSpan = 24;
        this.showMobile = true;
      } else {
        this.layoutSpan = 18;
        this.searchSpan = 6;
        this.showMobile = false;
      }
    },
    async deleteCandidate(id) {
      try {
        await api.remove("candidate", id);
      } catch (e) {
        this.$notify.error("Error deleting candidate.");
      }

      await this.fetchData();
    },
    async fetchData() {
      this.setLayout();
      this.loading = true;

      try {
        await Promise.all([
          api.get("candidate").then((d) => (this.list = d)),
          api.get("admin/field").then((d) => (this.fields = d)),
        ]);
      } catch (e) {
        this.$notify.error("Failed to retrieve listing information.");
        this.loading = false;
      }

      this.loading = false;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.candidates = fetchList(this.listQuery, this.list);
      // EL table columns wont display bools only strings
      for (const c of this.candidates) {
        c.isconfidential = `${c.isconfidential}`;
      }
      this.total = this.list.length;
      this.loading = false;
    },
    AddCandidate() {
      this.$router.push({
        name: "NewCandidate",
      });
    },

    setDateSearchParamsByType(searchType) {
      const form = this.form;

      if (form[`date${searchType}before`]) {
        form[`date${searchType}before`] = form[`date${searchType}before`]
          .toISOString()
          .split("T")[0];
      } else {
        form[`date${searchType}before`] = new Date()
          .toISOString()
          .split("T")[0];
      }

      if (form[`date${searchType}after`]) {
        form[`date${searchType}after`] = form[`date${searchType}after`]
          .toISOString()
          .split("T")[0];
      } else {
        form[`date${searchType}after`] = "1970-01-01";
      }
    },

    async submitForm() {
      this.loading = true;
      const form = this.form;
      // Strip away the times for search object
      if (form.datelistedafter || form.datelistedbefore) {
        this.setDateSearchParamsByType("listed");
      }

      if (form.dateupdatedafter || form.dateupdatedbefore) {
        this.setDateSearchParamsByType("updated");
      }

      const postTask = api.post("candidate/search", form);
      this.resetSearch();

      try {
        const data = await postTask;
        if (data === "") {
          this.list = [];
        } else {
          this.list = data;
        }

        this.getList();
      } catch (e) {
        this.$notify.error("Error performing candidate search.");
      }

      this.loading = false;
    },
    TogglePrintView() {
      this.showPrint = !this.showPrint;
    },
    resetSearch() {
      const form = this.form;

      (form.firstname = ""),
        (form.lastname = ""),
        (form.isconfidential = undefined),
        (form.fieldid = null),
        (form.datelistedafter = undefined),
        (form.datelistedbefore = undefined),
        (form.dateupdatedafter = undefined),
        (form.dateupdatedbefore = undefined);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/print-view.scss";

.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
</style>
