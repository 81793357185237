import Vue from "vue";
import VueRouter from "vue-router";
import Route from "../layout/NestedRoute.vue";
import Layout from "../layout/Layout.vue";

import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import Registration from "@/views/Registration.vue";
import Companies from "@/views/Company/Index.vue";
import CompanyDetail from "@/views/Company/Details.vue";
import Candidates from "@/views/Candidates/Index.vue";
import CandidateDetail from "@/views/Candidates/Details.vue";
import Users from "@/views/Users/Index.vue";
import UserCreate from "@/views/Users/UserCreate.vue";
import UserDetails from "@/views/Users/UserDetails.vue";
import UserProfile from "@/views/Users/UserProfile.vue";
import Manage from "@/views/Admin/Manage.vue";
import Jobs from "@/views/Jobs/Index.vue";
import JobDetails from "@/views/Jobs/Details.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ChangePassword from "@/views/ChangePassword.vue";
import NotFound from "@/views/NotFound.vue";

import { Auth } from "aws-amplify";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        beforeEnter: routeGuard,
        meta: {
          title: "Home",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
      },
      {
        path: "/company",
        component: Route,
        meta: {
          title: "Companies",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
        children: [
          {
            path: "",
            name: "Companies",
            component: Companies,
            beforeEnter: routeGuard,
            meta: {
              title: "View All Companies",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/company/new",
            name: "NewCompany",
            component: CompanyDetail,
            beforeEnter: routeGuard,
            meta: {
              title: "Add A Company",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/company/:id",
            name: "CompanyDetails",
            component: CompanyDetail,
            beforeEnter: routeGuard,
            meta: {
              title: "Edit Company",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
        ],
      },
      {
        path: "/candidate",
        component: Route,
        beforeEnter: routeGuard,
        meta: {
          title: "Candidates",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
        children: [
          {
            path: "",
            name: "Candidates",
            component: Candidates,
            meta: {
              title: "View All Candidates",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/candidate/new",
            name: "NewCandidate",
            component: CandidateDetail,
            meta: {
              title: "Add A Candidate",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/candidate/:id",
            name: "CandidateDetails",
            component: CandidateDetail,
            meta: {
              title: "Edit Candidate",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
        ],
      },
      {
        path: "/users",
        component: Route,
        beforeEnter: routeGuard,
        meta: {
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
          title: "Users",
        },
        children: [
          {
            path: "",
            name: "Users",
            component: Users,
            meta: {
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
              title: "Users",
            },
          },
          {
            path: "/users/edit/:username",
            name: "UserDetails",
            component: UserDetails,
            meta: {
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
              title: "Edit User",
            },
          },
          {
            path: "/users/create",
            name: "CreateUser",
            component: UserCreate,
            meta: {
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
              title: "User Creation",
            },
          },
        ],
      },
      {
        path: "/jobs",
        component: Route,
        beforeEnter: routeGuard,
        meta: {
          title: "Jobs",
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "Jobs",
            component: Jobs,
            meta: {
              title: "Job Listings",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/jobs/details/:id",
            name: "JobDetails",
            component: JobDetails,
            meta: {
              title: "Job Details",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
          {
            path: "/jobs/details/new",
            name: "JobsNew",
            component: JobDetails,
            meta: {
              title: "Create A Job",
              requiresAuth: true,
              allowedUsers: ["Admin", "Master"],
            },
          },
        ],
      },
      {
        path: "/profile",
        name: "UserProfile",
        component: UserProfile,
        beforeEnter: routeGuard,
        meta: {
          title: "My Profile",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
        },
      },
      {
        path: "/admin",
        name: "Manage",
        component: Manage,
        beforeEnter: routeGuard,
        meta: {
          title: "Admin Settings",
          requiresAuth: true,
          allowedUsers: ["Admin", "Master"],
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      title: "Pay It Forward Login",
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    props: true,
    meta: { requiresAuth: false },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { requiresAuth: false },
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { requiresAuth: false },
  },
  {
    // Default route - requested route did not match any route as defined previously
    path: "*",
    name: "404",
    component: NotFound,
    meta: { requiresAuth: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

async function routeGuard(to, from, next) {
  let isAuthenticated = false;
  try {
    isAuthenticated = await Auth.currentAuthenticatedUser();
  } catch (err) {
    console.log(err);
  }
  if (isAuthenticated) {
    //weird bug coming from login. so if user is going home just allow.
    if (to.path === "/home") {
      document.title = to.meta.title;
      next();
    } else {
      let allowedUsers = [];
      to.matched.some((record) => (allowedUsers = record.meta.allowedUsers));

      const authedUserType = store.getters.getUserType;

      if (allowedUsers.includes(authedUserType)) {
        document.title = to.meta.title;
        next();
      } else {
        document.title = "Home";
        next({ path: "/home" });
      }
    }
  } else {
    try {
      Auth.signOut({ global: true });
    } catch (err) {
      console.log(err);
    }
    store.commit("logOut");
    next({
      path: "/login",
      params: { nextUrl: to.fullPath },
    });
  }
}

export default router;
