<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="6"
        ><div class=""><h1 class="page-header">Users</h1></div></el-col
      >
    </el-row>
    <el-row :gutter="40">
      <el-col :span="layoutSpan">
        <el-button class="add-button" @click="AddUser()">Add A User</el-button>
        <el-table
          v-loading="loading"
          :show-header="false"
          :data="users"
          style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <el-row>
                    <el-col :span="2">
                      <i class="el-icon-s-custom info-icon" />
                    </el-col>
                    <el-col :span="22">
                      <h3 class="info-header">
                        {{ prop.row.firstname }} {{ prop.row.lastname }}
                      </h3>
                      <span>Email: {{ prop.row.email }}</span
                      ><br />
                      <span>Phone: {{ prop.row.mobilephone }}</span>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'UserDetails',
                      params: { username: prop.row.username },
                    }"
                    tag="el-button"
                    class="el-button--mini"
                    >Details</router-link
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="deleteUser(prop.row.username)"
                    >Delete</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from "../../components/base/Pagination.vue";
import { fetchList } from "../../utils/filter.js";
//import store from "@/store/index.js";
const api = require("../../api/api.js");

export default {
  name: "Users",
  components: {
    Pagination,
  },
  data() {
    return {
      message: [],
      errors: [],
      users: null,
      list: null,
      total: 0,
      loading: false,
      listQuery: {
        page: 1,
        limit: 50,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      form: {
        name: "",
        type: "",
        state: "",
        city: "",
        username: "",
      },
      screenWidth: window.innerWidth,
      searchSpan: 6,
      layoutSpan: 24,
    };
  },

  // Fetches posts when the component is created.
  async mounted() {
    const that = this;

    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
    screenWidth(val) {
      this.screenWidth = val;
      this.setLayout();
    },
  },
  methods: {
    setLayout() {
      if (this.screenWidth < 900) {
        this.layoutSpan = 24;
        this.searchSpan = 24;
        this.showMobile = true;
      } else {
        this.layoutSpan = 18;
        this.searchSpan = 6;
        this.showMobile = false;
      }
    },
    async fetchData() {
      this.setLayout();
      this.loading = true;
      try {
        this.list = await api.get("users?request=all");
      } catch (e) {
        this.$notify.error("Failed to retrieve listing information.");
      }
      this.loading = false;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.users = fetchList(this.listQuery, this.list);
      this.total = this.list.length;
      this.loading = false;
    },
    async deleteUser(username) {
      this.loading = true;
      try {
        api.remove("users", username);
        await this.fetchData();
      } catch (e) {
        this.$notify.error("Failed to delete user.");
      }
      this.loading = false;
    },
    AddUser() {
      this.$router.push({
        name: "CreateUser",
      });
    },
    submitForm() {
      this.loading = true;
      this.list = api.post("user/search", this.form);
      this.getList();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
</style>
