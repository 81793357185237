var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("Edit User")]),
            ]),
          ]),
        ],
        1
      ),
      _vm.isLoaded
        ? _c("Details", { attrs: { isNew: false, username: _vm.userid } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }