<template>
  <div>
    <div class="app-wrapper">
      <div class="main-container">
        <div>
          <navbar />
        </div>
        <app-main />
      </div>
    </div>
    <div class="mobile-nav-seperator"></div>
    <mobile-nav />
  </div>
</template>

<script>
import Navbar from "../layout/components/Navbar.vue";
import MobileNav from "../layout/components/MobileNav.vue";
import AppMain from "../layout/components/AppMain.vue";

export default {
  name: "Layout",
  components: {
    Navbar,
    AppMain,
    MobileNav,
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .mobile-nav-seperator {
    margin-top: 25%;
    height: 20%;
  }
}
.app-wrapper {
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
