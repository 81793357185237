var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("div", { staticClass: "navWrapper" }, [
      _c(
        "div",
        {
          staticClass: "navbar",
          class: { active: _vm.isActive },
          attrs: { id: "menu" },
        },
        [
          _c(
            "el-menu",
            { attrs: { mode: "horizontal", router: true } },
            [
              _c(
                "el-menu-item",
                { attrs: { id: "logo", index: "/", route: { name: "Home" } } },
                [_c("i", { staticClass: "el-icon-s-home" }), _c("br")]
              ),
              _c(
                "el-menu-item",
                { attrs: { index: "/company", route: { name: "Companies" } } },
                [_c("i", { staticClass: "el-icon-s-shop" }), _c("br")]
              ),
              _c(
                "el-menu-item",
                { attrs: { index: "/jobs/create", route: { name: "Jobs" } } },
                [_c("i", { staticClass: "el-icon-suitcase" }), _c("br")]
              ),
              _c(
                "el-menu-item",
                {
                  attrs: {
                    index: "/candidates",
                    route: { name: "Candidates" },
                  },
                },
                [_c("i", { staticClass: "el-icon-user-solid" }), _c("br")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }