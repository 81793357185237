<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="6"
        ><div class=""><h1 class="page-header">Companies</h1></div></el-col
      >
    </el-row>
    <el-row :gutter="40">
      <el-col v-show="!showPrint" :span="searchSpan">
        <el-form
          ref="form"
          label-position="top"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="Company Name">
            <el-input v-model="form.companyname"></el-input>
          </el-form-item>
          <el-form-item label="Company Type">
            <el-select v-model="form.type" placeholder="Comany Type">
              <el-option label="Technology" value="technology"></el-option>
              <el-option label="Healthcare" value="healthcare"></el-option>
              <el-option
                label="Office Administration"
                value="office"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="State">
            <el-select v-model="form.state" placeholder="Comany Type">
              <el-option label="Nebraska" value="NE"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="City">
            <el-input v-model="form.city"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">Search</el-button>
            <el-button @click="resetForm">Clear</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="layoutSpan">
        <el-button class="add-button" @click="AddCompany()"
          >Add A Company</el-button
        >
        <el-button
          class="add-button hide-for-print"
          type="primary"
          @click="TogglePrintView()"
        >
          <i class="el-icon-printer" /> Toggle Print View
        </el-button>
        <el-table
          v-show="showPrint"
          v-loading="loading"
          :show-header="false"
          :data="companies"
          style="width: 100%"
          id="data-table"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <h3 class="info-header">{{ prop.row.companyname }}</h3>
                  <el-row class="printer-friendly-row">
                    <div class="printer-friendly-col">
                      <span class="printer-friendly-col--label">{{
                        prop.row.address_line
                      }}</span
                      ><br />
                      <span class="printer-friendly-col--label">
                        {{ prop.row.city }},</span
                      ><br />
                      <span class="printer-friendly-col--label">
                        {{ prop.row.zipcode }}</span
                      ><br />
                    </div>
                  </el-row>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-show="showMobile && !showPrint"
          v-loading="loading"
          :show-header="false"
          :data="companies"
          style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <el-row>
                    <el-col :span="22">
                      <h3 class="info-header">{{ prop.row.companyname }}</h3>
                      <i class="el-icon-location-information" />
                      <span>{{ prop.row.address_line }}</span>
                      <span> {{ prop.row.city }},</span>
                      <span> {{ prop.row.zipcode }}</span
                      ><br />
                      <i class="el-icon-phone-outline" />
                      <span> {{ prop.row.phone_number }}</span>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <router-link
                    :to="{
                      name: 'CompanyDetails',
                      params: { id: prop.row.id },
                    }"
                    tag="el-button"
                    class="el-button--mini"
                    >Details</router-link
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="deleteCompany(table.row.id)"
                    >Delete</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table
          v-show="!showMobile && !showPrint"
          v-loading="loading"
          :show-header="true"
          :data="companies"
          style="width: 100%"
        >
          <el-table-column prop="companyname" label="Company Name" sortable />
          <el-table-column prop="address_line" label="Address" sortable />
          <el-table-column prop="city" label="City" sortable />
          <el-table-column prop="state" label="State" sortable />
          <el-table-column prop="phone_number" label="Phone" />
          <el-table-column>
            <template slot-scope="prop">
              <div>
                <router-link
                  :to="{
                    name: 'CompanyDetails',
                    params: { id: prop.row.id },
                  }"
                  tag="el-button"
                  class="el-button--mini"
                  >Details</router-link
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="deleteCompany(prop.row.id)"
                  >Delete</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>

        <pagination
          class="hide-for-print"
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from "../../components/base/Pagination.vue";
import { fetchList } from "../../utils/filter.js";
//import store from "@/store/index.js";
const api = require("../../api/api.js");

export default {
  name: "companies",
  components: {
    Pagination,
  },
  data() {
    return {
      message: [],
      errors: [],
      companies: null,
      list: null,
      total: 0,
      loading: false,
      listQuery: {
        page: 1,
        limit: 50,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      form: {
        companyname: "",
        type: "",
        state: "",
        city: "",
      },
      screenWidth: window.innerWidth,
      searchSpan: 6,
      layoutSpan: 18,
      showMobile: false,
      showPrint: false,
    };
  },

  // Fetches posts when the component is created.
  async mounted() {
    const that = this;

    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    await this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
    screenWidth(val) {
      this.screenWidth = val;
      this.setLayout();
    },
  },
  methods: {
    setLayout() {
      if (this.screenWidth < 900) {
        this.layoutSpan = 24;
        this.searchSpan = 24;
        this.showMobile = true;
      } else {
        this.layoutSpan = 18;
        this.searchSpan = 6;
        this.showMobile = false;
      }
    },

    async fetchData() {
      this.setLayout();
      this.loading = true;

      try {
        this.list = await api.get("company");
      } catch (e) {
        this.$notify.error("Failed to retrieve listing information.");
      }

      this.loading = false;
      this.getList();
    },

    getList() {
      this.loading = true;
      this.companies = fetchList(this.listQuery, this.list);
      this.total = this.list.length;
      this.loading = false;
    },
    async deleteCompany(id) {
      try {
        await api.remove("company", id);
      } catch (e) {
        this.$notify.error("Error deleting company.");
      }

      this.fetchData();
    },
    AddCompany() {
      this.$router.push({
        name: "NewCompany",
      });
    },
    resetForm() {
      const form = this.form;
      form.companyname = "";
      form.type = "";
      form.state = "";
      form.city = "";
    },
    TogglePrintView() {
      this.showPrint = !this.showPrint;
    },
    async submitForm() {
      this.loading = true;

      try {
        const postTask = api.post("company/search", this.form);
        this.resetForm();
        const data = await postTask;
        if (data === "") {
          this.list = [];
        } else {
          this.list = data;
        }

        this.getList();
      } catch (e) {
        this.$notify.error("Error performing company search.");
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/print-view.scss";

.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
</style>
