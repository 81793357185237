import Vue from "vue";
import App from "./App.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import "./plugins/element.js";

import "@/styles/index.scss"; // global css

import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import { Auth } from "aws-amplify";

import VueQr from "vue-qr";

Amplify.configure({
  region: process.env.VUE_APP_AWS_REGION,
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID,
});
Vue.config.productionTip = false;

Vue.use(Auth);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.component("vue-qr", VueQr);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  VueQr,
  render: (h) => h(App),
}).$mount("#app");
