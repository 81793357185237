var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [
                _vm._v("Admin Settings"),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c("h2", [_vm._v("Pay It Forward Job Fields")]),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { "show-header": false, data: _vm.fields },
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-s-shop info-icon",
                                        }),
                                      ]),
                                      _c("el-col", { attrs: { span: 22 } }, [
                                        _c(
                                          "h3",
                                          { staticClass: "info-header" },
                                          [_vm._v(_vm._s(prop.row.field_name))]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteField(
                                            prop.row.fieldid
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c("h2", [_vm._v("Pay It Forward Statuses")]),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { "show-header": false, data: _vm.categories },
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 2 } }, [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-s-shop info-icon",
                                        }),
                                      ]),
                                      _c("el-col", { attrs: { span: 22 } }, [
                                        _c(
                                          "h3",
                                          { staticClass: "info-header" },
                                          [
                                            _vm._v(
                                              _vm._s(prop.row.category_name)
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteField(
                                            prop.row.categoryid
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }