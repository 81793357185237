var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("My Profile")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form",
                {
                  ref: "user",
                  attrs: {
                    model: _vm.user,
                    "label-width": "120px",
                    "label-position": "top",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "username", label: "Username" } },
                    [
                      _c("el-input", {
                        ref: "username",
                        attrs: {
                          name: "username",
                          placeholder: "Username",
                          disabled: true,
                        },
                        model: {
                          value: _vm.user.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "username", $$v)
                          },
                          expression: "user.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "User's Name" } },
                    [
                      _c("el-input", {
                        ref: "name",
                        attrs: {
                          name: "name",
                          placeholder: "User's Name",
                          disabled: true,
                        },
                        model: {
                          value: _vm.user.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "name", $$v)
                          },
                          expression: "user.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "User's Email" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "User's Phone" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.user.phone_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "phone_number", $$v)
                          },
                          expression: "user.phone_number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.user.enablejobnotifications,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "enablejobnotifications", $$v)
                            },
                            expression: "user.enablejobnotifications",
                          },
                        },
                        [_vm._v("Enable Job Listing Notifications")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.user.enablecandidatenotifications,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.user,
                                "enablecandidatenotifications",
                                $$v
                              )
                            },
                            expression: "user.enablecandidatenotifications",
                          },
                        },
                        [_vm._v("Enable Candidate Listing Notifications")]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("Save changes")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("label", [_vm._v("MFA Setup")]),
              _vm.preferredTOTP === "NOMFA"
                ? _c(
                    "el-row",
                    [
                      _vm._v(
                        " You can setup MFA below. Please use your preferred Software MFA. "
                      ),
                      _c("section", [
                        _c("div", { staticClass: "columns is-vcentered" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "column is-half is-offset-one-quarter",
                            },
                            [
                              _c("vue-qr", {
                                attrs: { text: _vm.MFAString, size: 250 },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("h2", [_vm._v("No ability to scan the QR code?")]),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "max-width": "95%",
                              "word-wrap": "break-word",
                            },
                          },
                          [
                            _vm._v(
                              " Enter the following Secret in your Multi-Factor Authentication App: " +
                                _vm._s(_vm.mfaSecret) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("el-input", {
                        attrs: { placeholder: "MFA Code" },
                        model: {
                          value: _vm.mfaCode,
                          callback: function ($$v) {
                            _vm.mfaCode = $$v
                          },
                          expression: "mfaCode",
                        },
                      }),
                      _c("el-button", { on: { click: _vm.confirmTOTP } }, [
                        _vm._v("Confirm MFA"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.preferredTOTP === "SOFTWARE_TOKEN_MFA"
                ? _c("el-row", [
                    _c("p", [_vm._v("Thanks for setting up Software MFA!")]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }