var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("Jobs")]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showPrint,
                  expression: "!showPrint",
                },
              ],
              attrs: { span: _vm.searchSpan },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-position": "top",
                    model: _vm.form,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Job Title" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.jobtitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "jobtitle", $$v)
                          },
                          expression: "form.jobtitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Job Field" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Candidate's Field" },
                          model: {
                            value: _vm.form.fieldid,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fieldid", $$v)
                            },
                            expression: "form.fieldid",
                          },
                        },
                        _vm._l(_vm.fields, function (field) {
                          return _c("el-option", {
                            key: field.fieldid,
                            attrs: {
                              label: field.field_name,
                              value: field.fieldid,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Sponsor" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Sponsor" },
                          model: {
                            value: _vm.form.pifsponsorid,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pifsponsorid", $$v)
                            },
                            expression: "form.pifsponsorid",
                          },
                        },
                        _vm._l(_vm.pifsponsors, function (sponsor) {
                          return _c("el-option", {
                            key: sponsor.rosterid,
                            attrs: {
                              label: sponsor.lastname,
                              value: sponsor.rosterid,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Company" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Company" },
                          model: {
                            value: _vm.form.companyid,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "companyid", $$v)
                            },
                            expression: "form.companyid",
                          },
                        },
                        _vm._l(_vm.companies, function (company) {
                          return _c("el-option", {
                            key: company.id,
                            attrs: {
                              label: company.companyname,
                              value: company.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Date listed between..." } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "All listings after...",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.form.datelistedafter,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "datelistedafter", $$v)
                          },
                          expression: "form.datelistedafter",
                        },
                      }),
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "All listings before...",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.form.datelistedbefore,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "datelistedbefore", $$v)
                          },
                          expression: "form.datelistedbefore",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Date last updated between..." } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "All listings after...",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.form.dateupdatedafter,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dateupdatedafter", $$v)
                          },
                          expression: "form.dateupdatedafter",
                        },
                      }),
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "All listings before...",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.form.dateupdatedbefore,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dateupdatedbefore", $$v)
                          },
                          expression: "form.dateupdatedbefore",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("Search")]
                      ),
                      _c("el-button", [_vm._v("Clear")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: _vm.layoutSpan } },
            [
              _c(
                "el-button",
                {
                  staticClass: "add-button hide-for-print",
                  on: {
                    click: function ($event) {
                      return _vm.AddJob()
                    },
                  },
                },
                [_vm._v("Add A Job")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "add-button hide-for-print",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.TogglePrintView()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-printer" }),
                  _vm._v(" Toggle Print View "),
                ]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPrint,
                      expression: "showPrint",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "show-header": false,
                    data: _vm.jobs,
                    id: "data-table",
                  },
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c("h3", { staticClass: "info-header" }, [
                                    _vm._v(_vm._s(prop.row.jobtitle)),
                                  ]),
                                  _c(
                                    "el-row",
                                    { staticClass: "printer-friendly-row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "printer-friendly-col" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label",
                                            },
                                            [_vm._v("Company:")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(prop.row.companyname)
                                            ),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label",
                                            },
                                            [_vm._v("Sponsor:")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(prop.row.pifsponsor)),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label",
                                            },
                                            [_vm._v("Date Listed:")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(prop.row.datelisted)),
                                          ]),
                                          _c("br"),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "printer-friendly-col" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label",
                                            },
                                            [_vm._v("# Openings:")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(prop.row.jobopenings)
                                            ),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label",
                                            },
                                            [_vm._v("Field:")]
                                          ),
                                          _c("span", [
                                            _vm._v(_vm._s(prop.row.field_name)),
                                          ]),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label",
                                            },
                                            [_vm._v("Last Updated:")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(prop.row.lastupdated)
                                            ),
                                          ]),
                                          _c("br"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMobile && !_vm.showPrint,
                      expression: "showMobile && !showPrint",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { "show-header": false, data: _vm.jobs },
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 22 } }, [
                                        _c(
                                          "h3",
                                          { staticClass: "info-header" },
                                          [_vm._v(_vm._s(prop.row.jobtitle))]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "Company: " +
                                              _vm._s(prop.row.companyname)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            "Number of Openings: " +
                                              _vm._s(prop.row.jobopenings)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            "Sponsor: " +
                                              _vm._s(prop.row.pifsponsor)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            "Field: " +
                                              _vm._s(prop.row.field_name)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            "Date Listed: " +
                                              _vm._s(prop.row.datelisted)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v(
                                            "Last Updated: " +
                                              _vm._s(prop.row.lastupdated)
                                          ),
                                        ]),
                                        _c("br"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "el-button--mini",
                                      attrs: {
                                        to: {
                                          name: "JobDetails",
                                          params: { id: prop.row.id },
                                        },
                                        tag: "el-button",
                                      },
                                    },
                                    [_vm._v("Details")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteJob(prop.row.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showMobile && !_vm.showPrint,
                      expression: "!showMobile && !showPrint",
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "jobs-table",
                    "show-header": true,
                    data: _vm.jobs,
                    "default-expand-all": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (prop) {
                            return prop.row.notes.length > 0
                              ? [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { id: "notes" },
                                    },
                                    [_vm._v(_vm._s(prop.row.notes))]
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "jobtitle",
                      label: "Job Title",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "field_name",
                      label: "Job Field",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyname",
                      label: "Company Name",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "jobopenings",
                      label: "Openings",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "datelisted",
                      label: "Date Listed",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "lastupdated",
                      label: "Last Updated",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pifsponsor",
                      label: "Sponsor",
                      sortable: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Controls" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (prop) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "el-button--mini",
                                    attrs: {
                                      to: {
                                        name: "JobDetails",
                                        params: { id: prop.row.id },
                                      },
                                      tag: "el-button",
                                    },
                                  },
                                  [_vm._v("Details")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteJob(prop.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                staticClass: "hide-for-print",
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }