var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "viewer", attrs: { id: "viewer" } }, [
    _c("ul", [
      "resume" in _vm.documentsList
        ? _c("li", [
            !_vm.showMobile
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.loadViewer("resume")
                          },
                        },
                      },
                      [_vm._v(" View Resume ")]
                    ),
                    _c(
                      "el-dialog",
                      {
                        staticClass: "viewer-dialog",
                        attrs: {
                          title: "Resume Viewer",
                          visible: _vm.resumeDialog,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.resumeDialog = $event
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "viewer-full-window" }, [
                          _c(
                            "div",
                            {
                              ref: "resume-viewer-window",
                              staticClass: "view-pane",
                              attrs: { id: "resume-viewer-window" },
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(_vm.documentsList["resume"].filename) +
                                    ":"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("br"),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                ref: "resume-test",
                                attrs: {
                                  loading: !_vm.loadedDocuments["resume"],
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadDoc("resume")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " Download " +
                                    _vm._s(
                                      _vm.documentsList["resume"].filename
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    _vm.resumeDialog = false
                                  },
                                },
                              },
                              [_vm._v("Close viewer")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "mobile-download-box" },
                  [
                    _c("h3", [_vm._v("Saved Resume")]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadDoc("resume")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.documentsList["resume"].filename) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      "coverletter" in _vm.documentsList
        ? _c("li", [
            !_vm.showMobile
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.loadViewer("coverletter")
                          },
                        },
                      },
                      [_vm._v(" View Cover Letter ")]
                    ),
                    _c(
                      "el-dialog",
                      {
                        staticClass: "viewer-dialog",
                        attrs: {
                          title: "Cover Letter Viewer",
                          visible: _vm.clDialog,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.clDialog = $event
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "viewer-full-window" }, [
                          _c(
                            "div",
                            {
                              ref: "coverletter-viewer-window",
                              staticClass: "view-pane",
                              attrs: { id: "coverletter-viewer-window" },
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.documentsList["coverletter"].filename
                                  ) + ":"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c("br"),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  loading: !_vm.loadedDocuments["coverletter"],
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadDoc("coverletter")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " Download " +
                                    _vm._s(
                                      _vm.documentsList["coverletter"].filename
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    _vm.clDialog = false
                                  },
                                },
                              },
                              [_vm._v("Close viewer")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "mobile-download-box" },
                  [
                    _c("h3", [_vm._v("Saved Cover Letter")]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadDoc("coverletter")
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.documentsList["coverletter"].filename) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      "supportingdocuments" in _vm.documentsList
        ? _c("li", [
            !_vm.showMobile
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.loadViewer("supportingdocuments")
                          },
                        },
                      },
                      [_vm._v(" View Supporting Docs ")]
                    ),
                    _c(
                      "el-dialog",
                      {
                        staticClass: "viewer-dialog",
                        attrs: {
                          title: "Supporting Documents Viewer",
                          visible: _vm.sdDialog,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.sdDialog = $event
                          },
                        },
                      },
                      [
                        _vm._l(
                          _vm.documentsList["supportingdocuments"],
                          function (document, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                class: _vm.supportingDocumentsViewerWindow(),
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    ref:
                                      "supportingdocuments-viewer-window-" +
                                      index,
                                    refInFor: true,
                                    staticClass: "view-pane",
                                    attrs: {
                                      id:
                                        "supportingdocuments-viewer-window-" +
                                        index,
                                    },
                                  },
                                  [
                                    _c("h3", [
                                      _vm._v(_vm._s(document.filename) + ":"),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _c("br"),
                        _c(
                          "div",
                          {
                            staticClass: "dialog-footer",
                            attrs: { slot: "footer" },
                            slot: "footer",
                          },
                          [
                            _vm._l(
                              _vm.documentsList["supportingdocuments"],
                              function (document, index) {
                                return _c(
                                  "el-button",
                                  {
                                    key: index,
                                    ref:
                                      "supportingdocuments-download-button-" +
                                      index,
                                    refInFor: true,
                                    attrs: {
                                      loading:
                                        !_vm.loadedDocuments[
                                          "supportingdocuments"
                                        ],
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadDoc(
                                          "supportingdocuments",
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Download " +
                                        _vm._s(document.filename) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    _vm.sdDialog = false
                                  },
                                },
                              },
                              [_vm._v("Close viewer")]
                            ),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "mobile-download-box" },
                  [
                    _c("h3", [_vm._v("Saved Supporting Documents")]),
                    _vm._l(
                      _vm.documentsList["supportingdocuments"],
                      function (document, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadDoc(
                                      "supportingdocuments",
                                      index
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(document.filename) + " ")]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                  ],
                  2
                ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }