<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="6"
        ><div class=""><h1 class="page-header">Admin Settings</h1></div></el-col
      >
    </el-row>
    <el-row :gutter="40">
      <h2>Pay It Forward Job Fields</h2>
      <el-col :span="24">
        <el-table
          v-loading="loading"
          :show-header="false"
          :data="fields"
          style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <el-row>
                    <el-col :span="2">
                      <i class="el-icon-s-shop info-icon" />
                    </el-col>
                    <el-col :span="22">
                      <h3 class="info-header">{{ prop.row.field_name }}</h3>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <el-button
                    size="mini"
                    type="danger"
                    @click="deleteField(prop.row.fieldid)"
                    >Delete</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <el-row :gutter="40">
      <h2>Pay It Forward Statuses</h2>
      <el-col :span="24">
        <el-table
          v-loading="loading"
          :show-header="false"
          :data="categories"
          style="width: 100%"
        >
          <el-table-column>
            <template slot-scope="prop">
              <div class="data-container">
                <div class="info">
                  <el-row>
                    <el-col :span="2">
                      <i class="el-icon-s-shop info-icon" />
                    </el-col>
                    <el-col :span="22">
                      <h3 class="info-header">{{ prop.row.category_name }}</h3>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <el-button
                    size="mini"
                    type="danger"
                    @click="deleteField(prop.row.categoryid)"
                    >Delete</el-button
                  >
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Pagination from "../../components/base/Pagination.vue";
import { fetchList } from "../../utils/filter.js";
//import store from "@/store/index.js";
const api = require("../../api/api.js");

export default {
  name: "Manage",
  components: {
    Pagination,
  },
  data() {
    return {
      message: [],
      errors: [],
      fields: null,
      categories: null,
      fieldList: null,
      categoryList: null,
      total: 0,
      loading: false,
      listQuery: {
        page: 1,
        limit: 50,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      form: {
        name: "",
        type: "",
        state: "",
        city: "",
      },
      screenWidth: window.innerWidth,
      searchSpan: 6,
      layoutSpan: 18,
    };
  },

  // Fetches posts when the component is created.
  mounted() {
    const that = this;

    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        that.screenWidth = window.screenWidth;
      })();
    };

    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth < 900) {
        this.layoutSpan = 24;
        this.searchSpan = 24;
      } else {
        this.layoutSpan = 18;
        this.searchSpan = 6;
      }
    },
  },
  methods: {
    async fetchData() {
      if (this.screenWidth < 900) {
        this.layoutSpan = 24;
        this.searchSpan = 24;
      }
      this.loading = true;

      this.categoryList = await api.get("admin/categories");
      this.fieldList = await api.get("admin/field");

      this.loading = false;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.fields = fetchList(this.listQuery, this.fieldList);
      this.categories = fetchList(this.listQuery, this.categoryList);
      this.loading = false;
    },
    async deleteField(id) {
      try {
        await api.remove("admin/field", id);
      } catch (e) {
        this.$notify.error("Failed to delete resource.");
      }
    },
    async deleteSubField(id) {
      try {
        await api.remove("admin/field/sub", id);
      } catch (e) {
        this.$notify.error("Failed to delete resource.");
      }
    },
    async deleteCategory(id) {
      try {
        await api.remove("admin/categories", id);
      } catch (e) {
        this.$notify.error("Failed to delete resource.");
      }
    },
    async submitForm() {
      this.loading = true;
      this.list = await api.post("company/search", this.form);
      this.getList();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
</style>
