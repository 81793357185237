<template>
  <div>
    <div class="login-container">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title">Pay It Forward</h3>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <i class="el-icon-user-solid"></i>
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="Username"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <i class="el-icon-unlock" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="Password"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="SignIn"
          />
          <span class="show-password" @click="showPassword">
            <i
              :class="
                passwordType === 'password' ? 'el-icon-view' : 'el-icon-lock'
              "
            />
          </span>
        </el-form-item>
        <el-form-item prop="resetcode">
          <span class="svg-container">
            <s<i class="el-icon-unlock" />
          </span>
          <el-input
            ref="password"
            v-model="loginForm.resetcode"
            placeholder="Reset Code"
            name="resetcode"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="SignIn"
          />
        </el-form-item>
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                :loading="loading"
                type="primary"
                style="margin-bottom: 30px; width: 100%"
                @click.native.prevent="SignIn"
                >Change Password</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "Login",
  data() {
    const specialCharacterList = `^$*.[\]{}()?\-"!@#%&\/\,><':;|_~\`+=)`;
    const validatePassword = (_rule, password, callback) => {
      if (password.length < 8) {
        callback(new Error(`Entered password is not long enough.`));
      }

      for (const exp of this.passwordRegularExpressions) {
        if (!exp.test(password)) {
          callback(
            new Error(
              `Password does not conform to policy. Must include one of ${specialCharacterList}, a number, and a combination of uppercase and lowercase letters.`
            )
          );
        }
      }

      callback();
    };
    return {
      signedIn: false,
      isValid: false,
      loginForm: {
        username: "",
        password: "",
        resetcode: "",
      },
      mfaForm: {
        mfaCode: "",
      },
      mfaRules: {
        mfaCode: [{ required: true, trigger: "blur" }],
      },
      msg: "",
      loginRules: {
        username: [{ required: true, trigger: "blur" }],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        resetcode: [{ required: true, trigger: "blur" }],
      },
      passwordRegularExpressions: [
        /[$*.[\]{}()?\-"!@#%&\/\,><':;|_~`+=)]+/,
        /[0-9]+/,
        /[A-Z]+/,
        /[a-z]+/,
      ],
      loading: false,
      passwordType: "password",
      redirect: undefined,
      associatedId: "",
      authenticated: false,
      mfaCode: "",
      confirmMFASetup: this.confirmMFA,
      user: "",
      isError: false,
      isMFAError: false,
      mfaType: "SMS_MFA",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    showPassword() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    async SignIn() {
      await this.validateForm();

      if (this.isValid) {
        try {
          await Auth.forgotPasswordSubmit(
            this.loginForm.username,
            this.loginForm.resetcode,
            this.loginForm.password
          );

          this.isSuccess = true;
          // redirect user to /login after 4 seconds
          setTimeout(() => {
            this.$router.push({
              name: "Login",
              query: { redirect: "/login" },
            }),
              4000;
          });
        } catch (error) {
          switch (error.code) {
            case "InvalidParameterException":
              this.$notify.error({
                title: "Error",
                message:
                  "Passwords must be atleast 8 characters long, including a mix of numbers, special characters, uppercase and lowercase letters.",
                duration: 7_500,
              });
              break;
            case "ExpiredCodeException":
              this.$notify.error({
                title: "Error",
                message:
                  "Password reset code expired or invalid. Ensure the code you have entered is correct. " +
                  "Otherwise, you must initiate a new password reset request.",
                duration: 10_000,
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "An error occurred.",
              });
              console.error(error);
              break;
          }
        }
      }
    },
    async validateForm() {
      this.$refs.loginForm.validate((valid) => {
        this.isValid = valid;
      });
    },
    async confirmMFACode() {
      const user = this.user;
      const code = this.mfaForm.mfaCode;

      try {
        await Auth.confirmSignIn(
          user, // Return object from Auth.signIn()
          code, // Confirmation code
          this.mfaType
        ).then(async (user) => {
          this.$store.commit("logIn", {
            isLoggedIn: true,
            username: this.loginForm.username,
            token: user.signInUserSession.accessToken.jwtToken,
            isAdmin:
              user.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes("Admin"),
            userType:
              user.signInUserSession.accessToken.payload["cognito:groups"][0],
          });

          this.$router.push({ name: "Home" });
        });
      } catch (err) {
        this.$notify.error("Error encountered during MFA authentication.");
        this.isMFAError = true;
        throw err;
      }
    },
  },
};
</script>

<style lang="scss">
/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      height: 47px;
    }
  }

  .el-form-item {
    margin: 36px 0;
  }

  .forgot {
    color: #060606;

    & :hover {
      color: #060606;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .login-error {
    color: white;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 80px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-password {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}

.el-button {
  padding: 16px 20px;
  border-radius: 0;
}

.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
  background-color: white;
  border-color: #dbfa55;
}
</style>
